import React from 'react';
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText, Link } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PricingCard = ({ title, price, features, link }) => {

  return (
    <Box
      sx={{
        gap: '20px',
        border: '1px solid #fff000',
        borderRadius: '8px',
        padding: '20px',
        backgroundColor: '#1b1b1b',
        textAlign: 'center',
        boxShadow: `
          0 0 5px #fff000,
          0 0 10px #fff000
        `,
      }}
    >
      <Typography variant="h5" sx={{ color: '#ffffff', fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Typography variant="h6" color="#fff000" sx={{ marginY: '15px' }}>
        {price}
      </Typography>
      <List dense>
        {features.map((feature, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <CheckCircleIcon sx={{color: "#fff000"}} />
            </ListItemIcon>
            <ListItemText primary={feature} sx={{ color: '#ffffff' }} />
          </ListItem>
        ))}
      </List>
      <Link href={link} target="_blank" underline="none">
        <Button 
          variant="contained" 
          sx={{ 
            marginTop: '20px', 
            textTransform: 'none', 
            backgroundColor: '#fff000', 
            color: '#1b1b1b',
            '&:hover': {
              backgroundColor: '#e6e600',
            },
            border: '1px solid #fff000',
            borderRadius: '8px',
            boxShadow: `
              0 0 5px #fff000,
              0 0 10px #fff000
            `,
          }}
        >
          Escolher Serviço
        </Button>
      </Link>
    </Box>
  );
};

export default PricingCard;
