import React, { useState, useEffect } from 'react';
import { Fab } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const ScrollToTopButton = () => {
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        showButton && (
            <Fab
                onClick={scrollToTop}
                size="small"
                style={{
                    position: 'fixed',
                    bottom: '60px',
                    right: '10px',
                    backgroundColor: 'rgba(51, 51, 51, 0.5)',
                    color: '#fff',
                }}
            >
                <ArrowUpwardIcon />
            </Fab>
        )
    );
};

export default ScrollToTopButton;
