import { Box, Typography, Button } from '@mui/material';
import JapaScriptLogoNeon from '../../assets/servicos/JapaScriptLogoNeon.png';
import { Link as ScrollLink } from 'react-scroll';

const HeroSection = () => {
  return (
    <Box
      id="hero"
      sx={{
        height: { xs: '100vh', md: '100vh' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `#1b1b1b`,
        padding: { xs: '0', md: '0' },
        paddingTop: { xs: '10', md: '0' },
      }}
    >
      <Box sx={{
        
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'left', 
        color: '#fff',
        gap: { xs: '20px', md: '100px' },
      }}>
        <Box sx={{ marginRight: { xs: '0', md: '40px' }, textAlign: { xs: 'center', md: 'left' } }}>
          <Typography variant="h3" sx={{ marginBottom: '20px', fontSize: { xs: '25px', md: '40px' } }}>
            Landing Pages
          </Typography>
          <Typography variant="h3" sx={{ marginBottom: '20px', fontSize: { xs: '25px', md: '40px' } }}>
            Sistemas Sob Demanda
          </Typography>
          <Typography variant="h3" sx={{ marginBottom: '20px', fontSize: { xs: '25px', md: '40px' } }}>
            Indentidade Visual
          </Typography>
        </Box>
        <img src={JapaScriptLogoNeon} alt="JapaScriptLogoNeon" width='320px' style={{ maxWidth: '80%' }} />
      </Box>
      <ScrollLink to="services" smooth={true} duration={1000}>
        <Button sx={{ marginTop: '20px', fontSize: '20px', width: '200px',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          backgroundColor: '#fff000', 
          color: '#1b1b1b',
          '&:hover': {
            backgroundColor: '#e6e600',
          },
          border: '1px solid #fff000',
          borderRadius: '8px',
          boxShadow: `
            0 0 5px #fff000,
            0 0 10px #fff000
          `, }} variant="contained">
          SAIBA MAIS
        </Button>
      </ScrollLink>
    </Box>
  );
};

export default HeroSection;