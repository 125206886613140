import React from 'react';
import { Typography, Box } from '@mui/material';

const AboutUs = () => {
  return (
    <Box
      id="about"
      sx={{
        padding: { xs: 4, md: 8 },
        backgroundColor: '#1b1b1b',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h4"
        sx={{
          marginBottom: 4,
          color: '#ffffff',
        }}
      >
        Sobre a Japascripts
      </Typography>

      <Typography variant="body1" sx={{ marginBottom: 4, color: '#ffffff', maxWidth: '800px', margin: 'auto' }}>
        Somos uma pequena equipe apaixonada por melhorar sistemas que muitas vezes carecem de funcionalidades essenciais e sofrem com falhas na experiência visual.<br />
        Nosso foco é entender as necessidades do seu negócio e transformar desafios em oportunidades de crescimento, utilizando tecnologias modernas e um suporte ágil e eficiente.
      </Typography>
    </Box>
  );
};

export default AboutUs;
