import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import { Link as ScrollLink } from 'react-scroll';

const Header = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    { label: 'Serviços', to: 'services' },
    { label: 'Tabela de Preços', to: 'pricingTable' },
    { label: 'Depoimentos', to: 'testimonial' },
    { label: 'Sobre Nós', to: 'about' },
  ];

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: 'rgba(51, 51, 51, 0.5)',
        backdropFilter: 'blur(10px)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
        boxShadow: 'none',
      }}
    >
      <Toolbar>
        <ScrollLink
          to="hero"
          smooth={true}
          duration={500}
          style={{ textDecoration: 'none', flexGrow: 1 }}
        >
          <Typography variant="h6" sx={{ color: '#fff', cursor: 'pointer' }}>
            Japascripts
          </Typography>
        </ScrollLink>

        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              onClick={toggleDrawer}
              sx={{ color: '#fff' }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer}
              sx={{
                '& .MuiDrawer-paper': {
                  backgroundColor: 'rgba(51, 51, 51, 0.9)',
                  color: '#fff',
                },
              }}
            >
              <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer}>
                <List>
                  {menuItems.map((item) => (
                    <ScrollLink
                      key={item.to}
                      to={item.to}
                      smooth={true}
                      duration={500}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItem button>
                        <ListItemText
                          primary={item.label}
                          primaryTypographyProps={{
                            sx: { color: '#fff', fontWeight: 'bold' },
                          }}
                        />
                      </ListItem>
                    </ScrollLink>
                  ))}
                </List>
              </Box>
            </Drawer>
          </>
        ) : (
          <>
            {menuItems.map((item) => (
              <ScrollLink
                key={item.to}
                to={item.to}
                smooth={true}
                duration={500}
                style={{ textDecoration: 'none' }}
              >
                <Button
                  color="inherit"
                  sx={{
                    color: 'rgba(255, 255, 255, 0.8)',
                    transition: 'color 0.3s ease',
                    '&:hover': {
                      color: 'rgba(255, 255, 255, 1)',
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                      backdropFilter: 'blur(5px)',
                    },
                  }}
                >
                  {item.label}
                </Button>
              </ScrollLink>
            ))}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
