import React from 'react';
import { Card, CardContent, Typography, Avatar, Box, Rating } from '@mui/material';

const TestimonialCard = ({ name, role, image, testimonial, rating }) => {
  const cardStyles = {
    maxWidth: 400,
    padding: '20px',
    textAlign: 'center',
    margin: 'auto',
    backgroundColor: '#1b1b1b',
    borderRadius: '8px',
    border: '2px solid transparent',
    animation: 'borderGlow 3s infinite',
  };

  const avatarStyles = {
    width: 80,
    height: 80,
    margin: '0 auto 15px',
  };

  return (
    <>
      <style>
        {`
          @keyframes borderGlow {
            0% {
              border-color: #fff000; /* Amarelo */
              box-shadow: 0 0 5px #fff000, 0 0 10px #fff000;
            }
            50% {
              border-color: #ffffff; /* Branco */
              box-shadow: 0 0 5px #ffffff, 0 0 10px #ffffff;
            }
            100% {
              border-color: #fff000; /* Amarelo */
              box-shadow: 0 0 5px #fff000, 0 0 10px #fff000;
            }
          }
        `}
      </style>
      <Card style={cardStyles}>
        <Box paddingTop={'16px'}>
          <Avatar src={image} alt={name} style={avatarStyles} />
        </Box>
        <CardContent>
          <Typography variant="body1" color="#ffffff" sx={{ fontStyle: 'italic' }}>
            "{testimonial}"
          </Typography>
          <Box mt={2}>
            <Typography variant="h6" color="#ffffff">
              {name}
            </Typography>
            <Typography variant="subtitle2" color="#ffffff">
              {role}
            </Typography>
            <Rating value={rating} readOnly precision={1} />
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default TestimonialCard;
