import React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import TestimonialCard from './TestimonialCard';
import Alex from '../../assets/Alex.jpg';

const TestimonialSection = () => {
  const testimonials = [
    {
      name: 'Alex Santos',
      role: 'Proprietário da Clínica Vita Center',
      image: Alex,
      testimonial:
        'Ótimo trabalho, superou minhas expectativas. Recomendo!',
      rating: 5,
    },
  ];

  return (
    <Box id="testimonial" sx={{ padding:'60px 40px 60px 40px', backgroundColor: '#1b1b1b' }}>
      <Typography variant="h4" align="center" color="#ffffff" gutterBottom>
        O que nossos clientes dizem
      </Typography>
      <Typography variant="subtitle1" align="center" color="#ffffff" gutterBottom>
        Depoimento de quem confiou em nosso trabalho
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <TestimonialCard {...testimonial} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TestimonialSection;
