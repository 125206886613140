import React from 'react';
import { Box } from '@mui/material';
import Header from '../components/Home/Header';
import HeroSection from '../components/Home/HeroSection';
import Footer from '../components/Home/Footer';
import ScrollToTopButton from '../components/Home/ScrollToTopButton';
import PricingTable from '../components/Home/PricingTable';
import ServicesSection from '../components/Home/ServicesSection';
import AboutUs from '../components/Home/AboutUs';
import TestimonialSection from '../components/Home/TestimonialSection';

const HomePage = () => {
  return (
    <Box>
      <Header />
      <HeroSection />
      <ServicesSection />
      <PricingTable />
      <TestimonialSection />
      <AboutUs />
      <Footer />
      <ScrollToTopButton />
    </Box>
  );
};

export default HomePage;
