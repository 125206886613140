import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import PricingCard from './PricingCard';

const PricingTable = () => {
  return (
    <Box id="pricingTable" sx={{ padding: '40px', paddingTop:'80px', backgroundColor: '#1b1b1b' }}>
      <Typography variant="h4" align="center" color='#ffffff' gutterBottom>
        Tabela de Preços
      </Typography>
      <Typography variant="body1" align="center" color='#ffffff' sx={{ marginBottom: '30px' }}>
        Escolha o serviço que melhor atende às suas necessidades.
      </Typography>
      <Grid container spacing={4} justifyContent="center">
      <Grid item xs={12} md={4}>
        <PricingCard 
          title="Landing Page" 
          price="R$ 300,00" 
          features={['Implementação completa', 'Personalizado do seu jeito', 'Domínio próprio do seu negócio']}
          link="https://api.whatsapp.com/send?phone=5551980432414&text=Ol%C3%A1,%20gostaria%20de%20solicitar%20a%20cria%C3%A7%C3%A3o%20de%20uma%20Landing%20Page!"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <PricingCard 
          title="Pacote Completo" 
          price="R$ 500,00" 
          features={['Criamos a identidade visual do seu negócio', 'Landing Page exclusiva', 'Atualizações gratuitas']}
          link="https://api.whatsapp.com/send?phone=5551980432414&text=Ol%C3%A1,%20gostaria%20de%20solicitar%20o%20Pacote%20Completo!"
        />
      </Grid>
      <Grid item xs={12} md={4}>
          <PricingCard 
            title="Sistemas sob Demanda" 
            price="Valor a combinar" 
            features={['Acesso completo ao código-fonte', 'Suporte 24 horas', 'Documentação completa']}
            link="https://api.whatsapp.com/send?phone=5551980432414&text=Ol%C3%A1,%20gostaria%20da%20cria%C3%A7%C3%A3o%20de%20um%20sistema!"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PricingTable;
