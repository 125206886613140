import React, { useState } from 'react';
import { Box, Typography, Link, IconButton, Snackbar } from '@mui/material';
import { Email, ContentCopy, Phone } from '@mui/icons-material';

const Footer = () => {
  const [open, setOpen] = useState(false);

  const handleCopyEmail = () => {
    navigator.clipboard.writeText('contato@japascripts.com');
    setOpen(true);
  };

  const handleCopyPhone = () => {
    navigator.clipboard.writeText('51980432414');
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: '#333', color: '#fff', textAlign: 'center' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2 }}>
        <Email sx={{ marginRight: 1 }} />
        <Link href="mailto:contato@japascripts.com" color="inherit" underline="none">
          contato@japascripts.com
        </Link>
        <IconButton onClick={handleCopyEmail} sx={{ color: '#fff' }}>
          <ContentCopy />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2 }}>
        <Phone sx={{ marginRight: 1 }} />
        <Link href="https://wa.me/5551980432414" target="_blank" color="inherit" underline="none">
          (51) 98043-2414
        </Link>
        <IconButton onClick={handleCopyPhone} sx={{ color: '#fff' }}>
          <ContentCopy />
        </IconButton>
      </Box>
      <Box>
        <Typography variant="body2" sx={{ marginTop: 2 }}>
          &copy; {new Date().getFullYear()} Japascripts.
        </Typography>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        message="Copiado!"
      />
    </Box>
  );
};

export default Footer;