import React from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import WebIcon from '@mui/icons-material/Web';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const services = [
  {
    icon: <DesignServicesIcon fontSize="large" sx={{ color: '#fff000' }} />,
    title: 'Criação de Identidade Visual',
    description: 'Desenvolvemos logos, paletas de cores e branding para destacar sua marca.',
  },
  {
    icon: <WebIcon fontSize="large" sx={{ color: '#fff000' }} />,
    title: 'Desenvolvimento de Landing Pages',
    description: 'Criamos páginas de alta conversão, personalizadas para seu negócio.',
  },
  {
    icon: <SupportAgentIcon fontSize="large" sx={{ color: '#fff000' }} />,
    title: 'Suporte 24 horas',
    description: 'Estamos disponíveis 24/7 para garantir que tudo funcione perfeitamente.',
  },
];

const ServicesSection = () => {
  return (
    <Box
      id="services"
      sx={{
        padding: { xs: 7, sm: 6, md: 8 },
        backgroundColor: '#1b1b1b',
        textAlign: 'center',
      }}
    >
      <Typography
        variant="h4"
        sx={{ marginBottom: 4, color: '#ffffff' }}
      >
        Nossos Serviços
      </Typography>

      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                height: '100%',
                backgroundColor: '#1b1b1b',
                textAlign: 'center',
                boxShadow: `
                  0 0 5px #fff000,
                  0 0 10px #fff000
                `,
                '&:hover': {
                    boxShadow: `
                    0 0 5px #fff000,
                    0 0 10px #fff000,
                    0 0 20px #fff000
                    `,

                  },
              }}
            >
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
                  {service.icon}
                </Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#ffffff' }}>
                  {service.title}
                </Typography>
                <Typography sx={{ marginTop: 1, color: '#ffffff' }}>
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServicesSection;
